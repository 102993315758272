export const ALLPROPERTY = [
  // **A-EI** //
  "align-content",
  "align-items",
  "align-self",
  "all",
  "animation",
  "animation-delay",
  "animation-direction",
  "animation-duration",
  "animation-fill-mode",
  "animation-iteration-count",
  "animation-name",
  "animation-play-state",
  "animation-timing-function",
  // **B-BI** //
  "background-attachment",
  "background-blend-mode",
  "background-clip",
  "background-color",
  "background-image",
  "background-origin",
  "background-position",
  "background-repeat",
  "background-size",
  "background",
  "border-bottom-color",
  "border-bottom-left-radius",
  "border-bottom-right-radius",
  "border-bottom-style",
  "border-bottom-width",
  "border-bottom",
  "border-collapse",
  "border-color",
  "border-image",
  "border-image-outset",
  "border-image-repeat",
  "border-image-source",
  "border-image-slice",
  "border-image-width",
  "border-left-color",
  "border-left-style",
  "border-left-width",
  "border-left",
  "border-radius",
  "border-right-color",
  "border-right-style",
  "border-right-width",
  "border-right",
  "border-spacing",
  "border-style",
  "border-top-color",
  "border-top-left-radius",
  "border-top-right-radius",
  "border-top-style",
  "border-top-width",
  "border-top",
  "border-width",
  "border",
  "bottom",
  "box-shadow",
  "box-sizing",
  // **C-CI** //
  "caret-color",
  "clear",
  "clip",
  "clip-path",
  "color",
  "columns",
  "column-count",
  "column-fill",
  "column-gap",
  "column-rule",
  "column-rule-color",
  "column-rule-style",
  "column-rule-width",
  "column-span",
  "column-width",
  "cursor",
  // **D-DI** //
  "display",
  // **E-I** //
  "elevation",
  "empty-cells",
  // **F-EF** //
  "filter",
  "flex",
  "flex-basis",
  "flex-direction",
  "flex-flow",
  "flex-grow",
  "flex-shrink",
  "flex-wrap",
  "float",
  "font-family",
  "font-size",
  "font-stretch",
  "font-style",
  "font-weight",
  "font",
  // **G-GI** //
  "gap",
  "grid-area",
  "grid-auto-columns",
  "grid-auto-flow",
  "grid-auto-rows",
  "grid-column-end",
  "grid-column-gap",
  "grid-column-start",
  "grid-column",
  "grid-gap",
  "grid-row-end",
  "grid-row-gap",
  "grid-row-start",
  "grid-row",
  "grid-template-areas",
  "grid-template-columns",
  "grid-template-rows",
  "grid-template",
  "grid",
  // **H-EICH** //
  "height",
  // **I-EI** //
  "inset",
  // **J-JEY** //
  "justify-content",
  "justify-items",
  "justify-self",
  // **L-EL** //
  "left",
  "letter-spacing",
  "list-style",
  "line-break",
  "line-height",
  "list-style-image",
  "list-style-type",
  "list-style-position",
  // **M-EM** //
  "margin-bottom",
  "margin-left",
  "margin-right",
  "margin-top",
  "margin",
  "margin-block",
  "margin-inline",
  "max-height",
  "max-width",
  "min-height",
  "min-width",
  // **N-EN** //
  "nav-up",
  "nav-down",
  "nav-left",
  "nav-right",
  // **O-OU** //
  "object-fit",
  "object-position",
  "opacity",
  "order",
  "outline-color",
  "outline-offset",
  "outline-style",
  "outline-width",
  "outline",
  "overflow",
  "overflow-wrap",
  "overflow-x",
  "overflow-y",
  // **P-PI** //
  "padding-bottom",
  "padding-left",
  "padding-right",
  "padding-top",
  "padding",
  // "pseudos",
  "padding-inline",
  "padding-block",
  "place-content",
  "place-items",
  "place-self",
  "position",
  // **R-AR** //
  "resize",
  "rest",
  "right",
  "row-gap",
  // **S-ES** //
  "size",
  "stress",
  "text-align",
  "text-align-all",
  "text-align-last",
  "text-decoration",
  "text-decoration-color",
  "text-decoration-line",
  "text-decoration-skip",
  "text-decoration-style",
  "text-justify",
  "text-overflow",
  "text-shadow",
  "text-transform",
  "text-underline-position",
  "top",
  "transform",
  "transform-box",
  "transform-origin",
  "transform-style",
  "transition",
  "transition-delay",
  "transition-duration",
  "transition-property",
  "transition-timing-function",
  // **V-UVI** //
  "vertical-align",
  "visibility",
  // **W-WADLIU** //
  "white-space",
  "width",
  "will-change",
  "word-break",
  "word-spacing",
  "word-wrap",
  // **Z-SET** //
  "z-index",
] as const;